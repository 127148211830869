import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import ExampleDetail from '../../../components/Examples/ExampleDetail'
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/widgets/instagram-follow-hashtag.jpg';
import Constants from '../../../constatns'
import PlatformExample from '../../../components/Examples/PlarformExample';

// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="Instagramハッシュタグキャンペーン"
		  desc="このサンプルはInstagramでハッシュタグキャンペーンを行う方法です。ハッシュタグキャンペーンはユーザーのオリジナル投稿が多くの人のタイムラインに表示されるので、商品やブランドの認知度をあげるのに効果的です。キャンペーン後のUGCとしても使用できます。"
		  banner={exampleMetaImage}
		  pathname={'/examples/instagram/follow-hashtag'}
		  type={'Article'}
		  shortName="Instagramaハッシュタグキャンペーン"
		  createdAt="2021-12-01"
		/>
	  <Navbar />
	  <ContentBanner
		homePageText="Home"
		homePageUrl="/"
		activePageText="Instagramハッシュタグキャンペーン"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		},{
		  url: '/examples/instagram',
		  name: 'Instagramキャンペーン'
		}]}
	  />
	  <ExampleDetail
		title={"Instagramハッシュタグキャンペーン"}
		description={<>このサンプルはInstagramでハッシュタグキャンペーンを行う方法です。<br/>ハッシュタグキャンペーンはユーザーのオリジナル投稿が多くの人のタイムラインに表示されるので、売上向上やブランドの認知度をあげるのに効果的です。<br/>商品の写真はUGCとしてホームページに掲載して使用できます。</>}
		effects={[Constants.CAMPAIGN_EFFECTS.GET_EMAILS,Constants.CAMPAIGN_EFFECTS.GET_SNS_ACCOUNTS, Constants.CAMPAIGN_EFFECTS.GET_FOLLOWERS
		  , Constants.CAMPAIGN_EFFECTS.GET_MIDDLE_SPREAD, Constants.CAMPAIGN_EFFECTS.GAIN_RECOGNITION, Constants.CAMPAIGN_EFFECTS.SALE_PROMOTION, Constants.CAMPAIGN_EFFECTS.UGC]}
		plan={'ライトプラン以上'}
		promotionId="9bad2109fc6f739c26"
		platformExample={
		  <PlatformExample
			title="その他のInstagramキャンペーン"
			types={['instagramFollowLike', 'instagramFollowRepost', 'password', 'instagramShare', 'instagramPhotoContest']}
		  />
		}
	  />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples